
import { Options, Vue } from 'vue-class-component'
import Patient from '@/models/Patient'
import YesNoCurrHistRadio from '@/components/YesNoCurrHistRadio.vue'
import CurrHistTextarea from '@/components/CurrHistTextarea.vue'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    YesNoCurrHistRadio,
    CurrHistTextarea
  },
  methods: mapActions(['updateComorbidities', 'downloadComorbidities', 'updateContraindications', 'downloadContraindications']),
  computed: mapGetters(['patient', 'comorbidities', 'contraindications'])
})
export default class Comorbidity extends Vue {
  patient!: Patient
  comorbidities!: Patient['comorbidities']
  contraindications!: Patient['contraindications']

  // Actions
  updateComorbidities!: (payload: {
    id: number
    comorbidities: Patient['comorbidities']
  }) => Promise<void>

  updateContraindications!: (payload: {
    id: number
    contraindications: Patient['contraindications']
  }) => Promise<void>

  downloadComorbidities!: () => Promise<void>

  downloadContraindications!: () => Promise<void>

  created (): void {
    this.downloadComorbidities()
    this.downloadContraindications()
  }

  async submit (): Promise<void> {
    try {
      await this.updateComorbidities({
        id: this.patient.id,
        comorbidities: this.comorbidities
      })

      await this.updateContraindications({
        id: this.patient.id,
        contraindications: this.contraindications
      })

      this.$router.push({
        name: this.$route.meta.next,
        params: { id: this.patient.id }
      })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }
}
